<template>
  <div class="home">
    <div class="hometext">
          <welcome></welcome>
    </div>
     <OnzeHonden />
     <Nieuws />
  </div>
</template>
<script>
import Welcome from '@/components/Home/Text.vue';
import OnzeHonden from '@/components/Home/Onzehonden_Section.vue';
import Nieuws from '@/components/Home/Nieuws.vue';

export default {
  name: 'home',
  components: {
    Welcome,
    OnzeHonden,
    Nieuws,
  },
};
</script>
<style lang="less">

.hometext {
     display: inline-block;

     .readmore{
         margin: 10px 10px 15px 15px;
    }
}


</style>
