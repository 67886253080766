<template>
<v-container>
    <v-toolbar
        height="85"
        class="hidden-sm-and-down"
        scroll-off-screen scroll-target="#app" color="rgb(117, 117, 117, 0.9)" fixed clipped-left>
    <v-toolbar-title>
        <div class="logo">
            <router-link to="/">
                <v-img  :src ="require('@/static/logo_DeMeulenbarg-01.png')"></v-img>
            </router-link>
        </div>
    </v-toolbar-title>
    <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
            <v-btn flat small color="primary" active-class="active" to="/">Home</v-btn>
            <v-btn flat small color="primary" active-class="active" to="/overons">Over ons</v-btn>
            <v-btn flat small color="primary" active-class="active" to="/onzehonden">
            Onze Honden</v-btn>
            <v-btn flat small color="primary" active-class="active" to="/pups">Pups</v-btn>
            <v-btn flat small color="primary"
                active-class="active" to="/impressie">Impressie</v-btn>
            <v-btn flat small color="primary"
            active-class="active" to="" @click.stop="showScheduleForm=true">Contact</v-btn>
        </v-toolbar-items>
    </v-toolbar>

    <div id="mobilenav" class="hidden-md-and-up mobile-nav left mx-4 ">
        <v-icon mx-4 large @click.stop="drawer = !drawer" color="secondary">menu</v-icon>
        <v-navigation-drawer v-model="drawer"
            :mini-variant="mini"
            absolute
            temporary
            class="mobile-wrapper"
            >
            <v-layout >
                <v-flex ml-4 xs12 class="logo pt-4">
                    <v-img :src ="require('@/static/logo_DeMeulenbarg-01.png')"></v-img>
                </v-flex>
            </v-layout>
            <v-btn flat block class="nav-item primary--text custom-btn" to="/">Home</v-btn>
            <v-btn flat block class="nav-item primary--text custom-btn"
            to="/overons">Over ons</v-btn>
            <v-btn flat block class="nav-item primary--text custom-btn"
            to="/onzehonden">Onze Honden</v-btn>
            <v-btn flat block class="nav-item primary--text custom-btn"
            to="/pups">Pups</v-btn>
            <v-btn flat block class="nav-item primary--text custom-btn"
            to="/impressie">Impressie</v-btn>
            <v-btn flat block class="nav-item primary--text custom-btn"
            to="" @click.stop="showScheduleForm=true">Contact</v-btn>
        </v-navigation-drawer>
    </div>

    <Contact :visible="showScheduleForm" @close="showScheduleForm=false"/>

</v-container>

</template>

<script>

import Contact from '@/components/Site/Contact.vue';

export default {
  components: {
    Contact,
  },
  data() {
    return {
      drawer: null,
      mini: false,
      right: null,
      showScheduleForm: false,
    };
  },
};
</script>

<style lang="less" scoped>
.active{
    Background-color:#928674;
    color:black;
}

.logo{
    min-width: 200px;

}
.mobile-nav{
      overflow: visible;

         .custom-btn::before{
             color: transparent;
         }

         .logo{
            max-width: 100px;
         }
         .mobile-wrapper{
             background-color:#707070a6;
         }
    .nav-item{
        font-size:15px;

    }

    /* The sticky class is added to the navbar with JS when it reaches its scroll position */
    .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    }

    /* Add some top padding to the page content to prevent sudden quick movement
     (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
    .sticky + .content {
    padding-top: 60px;
    }

}

</style>
