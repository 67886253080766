<template>
  <v-container fluid grid-list class="secondary">
      <v-layout row  class="footer" id="footer">
          <v-flex xs12 sm6 md5 lg5>
              <v-list class="transparent ml-5">
              <p class="white--text text-md-left font-weight-regular title ml-3" >{{ header }}</p>
                  <v-list-tile class="contactline"
                  v-for="item in items"
                  :key="item.text"
                  v-bind:href="item.link"
                  >
                      <v-list-tile-action class="my-0">
                          <v-icon class="white--text">{{ item.icon }}</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content class="my-0">
                          <v-list-tile-title class="primary--text">
                              <p class="font-weight-regular title"> {{ item.text }}</p>
                          </v-list-tile-title>
                      </v-list-tile-content>
                  </v-list-tile>
              </v-list>
          </v-flex>
          <v-flex xs12 sm5 md4 lg3 offset-lg4 offset-md3 offset-sm1
            :class="{'pt-3': $vuetify.breakpoint.smAndDown}">
            <v-responsive>
              <v-img  class="nbg  ml-5" :src ="require ('@/static/nbg.png')"></v-img>
            </v-responsive>
          </v-flex>
      </v-layout>
      <v-card-actions
        class="secondary justify-center">&#169;{{ new Date().getFullYear() }} - Mats Media
      </v-card-actions>
  </v-container>
</template>
<script>

export default {
  data() {
    return {
      header: 'Kennel van de Meulenbarg',
      items: [

        {
          icon: 'email',
          text: 'info@demeulenbarg.nl',
          link: 'mailto:info@demeulenbarg.nl',
        },
        {
          icon: 'phone',
          text: '0577-491369',
          link: 'tel:0577-491369',
        },
        {
          icon: 'stay_primary_portrait',
          text: '06-22008102',
          link: 'tel:0622008102',
        },
      ],
    };
  },
  methods: {
    generateLink() {
      window.location.href = (this.item.link);
    },
  },
};
</script>
<style lang="less" scoped>
.footer{
  padding: 14px;

  .contactline{
    height:30px;
  }

  .nbg{
  max-width:250px;
  text-align:left;
  }
}


</style>
