const news = {
  newsItems: [
    {
      date: '01-08-2024:',
      title:
        'Alle 19 pups hebben een baasje gevonden!',
      subtitle:
        'Alle pups van Shiva v/d Meulenbarg x Max van de Lobohoeve & Quincy v/d Meulenbarg X Germali\'s Nero hebben een baasje gevonden. We kijken uit naar de toekomst met hopelijk nog meer mooie pups!',
      link: '/pups',
    },
    {
      date: '13-07-2024:',
      title:
        'Er zijn nog 3 pups beschikbaar, 2 teefjes en 1 reutje!',
      subtitle:
        'Van Shiva v/d Meulenbarg x Max van de Lobohoeve is er nog <b>1 reutje</b> beschikbaar en van Quincy v/d Meulenbarg X Germali\'s Nero zijn er nog <b>2 teefjes</b> beschikbaar! Neem contact op voor meer informatie!',
      link: '/pups',
    },
    {
      date: '30-05-2024:',
      title:
        'Pups geboren Shiva v/d Meulenbarg x Max van de Lobohoeve, nog aantal teefjes beschikbaar',
      subtitle:
        'Pups geboren van de combinatie Shiva v/d Meulenbarg x Max van de Lobohoeve er zijn nog een paar teefjes <b>beschikbaar!</b>',
      link: '/pups',
    },
    {
      date: '19-05-2024:',
      title:
        '10 pups geboren Quincy v/d Meulenbarg X Germali\'s Nero',
      subtitle:
        '10 pups geboren van de combinatie Quincy v/d Meulenbarg X Germali\'s Nero alle pups <b>reeds verkocht</b>',
      link: '/pups',
    },
  ],
  header: 'Het laatste nieuws',
};

export default news;
