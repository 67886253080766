<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      width="500"
    >
      <v-card class="contact">
        <v-card-title
          class="headline contact grey lighten-2"
          primary-title
          :class="{'title': $vuetify.breakpoint.xs}"
        >
          {{ header }}
        <v-card-actions class="closebutton">
          <v-spacer></v-spacer>
          <v-icon
            color="primary"
            text
            @click.stop="show=false"
          >
            close
          </v-icon>
        </v-card-actions>
        </v-card-title>
        <v-card-text class="contactlines">
            <v-list  class="transparent">
                <p class="white--text text-md-center font-weight-regular title" ></p>
                    <v-list-tile class="contactline flat"
                    v-for="item in items"
                    :key="item.text"
                    v-bind:href="item.link"
                    >
                <v-list-tile-action class="my-0">
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                </v-list-tile-action>
                    <v-list-tile-content class="my-0">
                        <v-list-tile-title class="primary--text">
                            <p class="font-weight-regular title"> {{ item.text }}</p>
                        </v-list-tile-title>
                    </v-list-tile-content>
                    </v-list-tile>
            </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {

  data() {
    return {
      dialog: true,
      header: 'Kennel van de Meulenbarg',
      items: [

        {
          icon: 'email',
          text: 'info@demeulenbarg.nl',
          link: 'mailto:info@demeulenbarg.nl',
        },
        {
          icon: 'phone',
          text: '0577-491369',
          link: 'tel:0577-491369',
        },
        {
          icon: 'stay_primary_portrait',
          text: '06-22008102',
          link: 'tel:0622008102',
        },
      ],
    };
  },
  props: ['visible'],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
};
</script>
<style lang="less" scoped>
.contact{
    background-color:gray!important;
    .closebutton{
        display: contents;
    }
    .contactlines{
        padding:0px!important;
    }
}
</style>
