<template>
<div class="background">
  <!-- <v-container color="blue" grid-list-md text-xs-center> -->
      <v-layout row wrap justify-center >
      <v-flex mb-2 mt-5
      :class="{'pr-0': $vuetify.breakpoint.smAndDown, 'pr-5': $vuetify.breakpoint.mdAndUp}"
      xs12 sm10 md5>
        <v-card flat color="transparent" >
              <v-img :src ="require('@/static/onze-dekreu2.jpg')"></v-img>
              <v-layout justify-center>
                <v-flex xs8>
                    <v-btn block color="primary" large to="/onzehonden#dekreu">Onze Honden</v-btn>
                </v-flex>
              </v-layout>
        </v-card>
      </v-flex>
      <v-flex mb-2 mt-5
      :class="{'pl-0': $vuetify.breakpoint.smAndDown, 'pl-5': $vuetify.breakpoint.mdAndUp}"
      xs12 sm10 md5>
        <v-card flat color="transparent" class="justify-center">
            <v-img :src ="require('@/static/pups01.jpg')"></v-img>
            <v-layout justify-center>
              <v-flex xs8>
                <v-btn block color="primary" large to="/pups">Verwacht Nest</v-btn>
              </v-flex>
            </v-layout>
        </v-card>
      </v-flex>
  </v-layout>
</div>
</template>
<script>
export default {

};
</script>
<style lang="less">
.background{
    background-color: #707070;
}
</style>
