import Vue from 'vue';
import Router from 'vue-router';
import VueSilentbox from 'vue-silentbox';
import Home from './views/Home.vue';

Vue.use(VueSilentbox);
Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/onzehonden',
      name: 'onzeHonden',
      to: '/onzehonden',
      component: () => import('./views/OnzeHonden.vue'),
    },
    {
      path: '/overons',
      name: 'overOns',
      to: '/overons',
      component: () => import('./views/OverOns.vue'),
    },
    {
      path: '/impressie',
      name: 'impressie',
      to: '/impressie',
      component: () => import('./views/Impression.vue'),
    },
    {
      path: '/pups',
      name: 'pups',
      to: '/pups',
      component: () => import('./views/Pups.vue'),
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    return { x: 0, y: 0 };
  },
});
