import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/stylus/app.styl';

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: '#ff9a00',
    secondary: '#707070',
  },
  breakpoint: {
    threshold: {
      md: 1600,
    },
    scrollbarWidth: 10,
  },
});
