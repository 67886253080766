<template>
    <v-layout justify-center row wrap mb-5>
        <v-flex xs9 my-5 class="wrapper">
            <div class="welcome" >
                <h2>{{ title}}</h2>
                <p class="text" v-for="msg in messages" :key="msg" >{{ msg }}</p>
            </div>
        </v-flex>
        <v-flex xs4>
            <v-btn block large color="primary readmore" to="/overons#hoehetbegon">Lees meer</v-btn>
        </v-flex>
    </v-layout>
</template>
<script>

export default {
  name: 'welcome',
  data() {
    return {
      messages: ['Van tijd tot tijd hebben wij een nestje met gezonde Mechelse Herder puppies. De pups worden in huis gesocialiseerd en verzorgd.',
        'Wekelijks staan we op het trainingsveld om onze honden te trainen, er wordt alleen met onze beste honden gefokt. Wij hebben zowel onze eigen Teefjes en Dekreu.',
        'Inmiddels heb ik jarenlange ervaring met het trainen en het fokken van Mechelse Herdershonden.'],
      title: 'Kennel van de Meulenbarg',
    };
  },
};
</script>

<style scoped lang="less">

.wrapper{
        margin-top: 20px!important;
    .welcome{
        h2{
            font-size: 40px;
            font-style: normal;
            color: #858585;
            font-weight: 500;

        }
        p.text{
            font-size:20px;
            font-weight: 500;
        }
    }
}
</style>
