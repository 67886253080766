<script>
import news from '../../data/news';

export default {
  data() {
    return {
      newsItems: news.newsItems,
      header: news.header,
    };
  },
};
</script>

<template>
  <v-layout align-center class="background2">
    <v-flex xs12 sm10 offset-sm1 my-5>
      <v-container>
        <v-card flat class="news" align-content-center>
          <v-list three-line>
            <h1 class="secondary--text" v-if="header" :key="header">
              {{ this.header }}
            </h1>
            <div class="newsitem">
              <template v-for="(newsItem, index) in this.newsItems">
                <v-list-tile :key="index" to="/pups">
                  <v-list-tile-content>
                    <v-list-tile-title class='news-title'><strong>{{newsItem.date}}</strong>{{ newsItem.title }}</v-list-tile-title>
                    <v-list-tile-sub-title
                      name="content"
                      class="newstext"
                      v-html="newsItem.subtitle"
                    >
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider
                  dark
                  class="customdivider"
                  color="orange"
                  :key="`divider-${index}`"
                ></v-divider>
              </template>
            </div>
          </v-list>
        </v-card>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<style lang="less">
.background2 {
  background-color: white;
  .news {
    border: 1px solid #707070;
    .newsitem {
      max-width: 80%;
      margin-left: 10%;
      margin-bottom: 25px;
      .news-title strong{
         margin-right: 0.5rem;
      }
      .newstext {
        font-size: 14px;
        color: #5e5e5e;
      }
      .customdivider {
        border: 1px solid #ff9a00;
      }
    }
  }
}
</style>
