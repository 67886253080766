<template>
  <div>
    <v-carousel height="640" v-show="!Mobile">
      <v-carousel-item v-for="(image, i) in images" :key="i" :src="image.src">
      </v-carousel-item>
      <div class="news-section text headline">
        <div class="news-item" v-for="newsItem in this.newsItems.slice(0,1)" :key="newsItem.title">
          <span>{{newsItem.title}}</span>
          <span>{{newsItem.subtitle}}</span>
        </div>
        <v-btn block class="" color="primary" to="pups"> Meer info </v-btn>
      </div>
    </v-carousel>
    <v-img v-show="Mobile" :src="images[0]"></v-img>
  </div>
</template>
<script>
import news from '../../data/news';

export default {


  data() {
    return {
      images: [
        { src: '../img/banner_01.jpg' },
        { src: '../img/banner_03.jpg' },
        { src: '../img/banner_02.jpg' },
      ],
      newsItems: news.newsItems,
    };
  },
  computed: {
    Mobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>
<style scoped>
.news-section {
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 600px;
  top: 180px;
  margin-left: 100px;
  padding: 15px 50px 20px 50px;
  position: absolute;
  background-color: #63625bb8;
  color: white;
}

.news-section > .news-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

.news-section > span:first-child::after  {
  content: '&';
  font-weight: bold;
}

.news-section > a {
  margin-top: 1rem;
}


</style>
