<template>
  <v-app id="app">
    <Navbar />
    <Slider />
    <v-content class="ma-0 pa-0 content">
      <transition >
        <router-view></router-view>
      </transition>
    </v-content>
    <Footer />
  </v-app>
</template>
<script>
import Slider from '@/components/Site/Slider.vue';
import Navbar from '@/components/Site/Navbar.vue';
import Footer from '@/components/Site/Footer.vue';


export default {
  components: {
    Navbar,
    Footer,
    Slider,
  },
  name: 'App',
  data() {
    return {

    };
  },

};
</script>


<style lang="less">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  li{
    list-style: none;
  }
  .nav {
    background-color: #626262b5;
    height: 150px;
    text-align: right;
    z-index: 2;
    position: relative;

    a {
      color: #ffa519;
      line-height: 150px;
    }

    .content{
      padding-top:0px!important;
    }
  }

  .container{
    padding: 0px;
  }

  .v-list__tile--link:hover{
    background:none;
  }

 h1,h2,h3{
      color: #858585;
    }
}
</style>
